<!-- src/layouts/LoginLayout.vue -->
<template>
  <div>
    <link rel="stylesheet" href="../css/login/bootstrap.min.css" />
    <link rel="stylesheet" href="../css/login/fontawesome-all.min.css" />
    <link rel="stylesheet" href="../css/login/iofrm-style.css" />
    <link rel="stylesheet" href="../css/login/iofrm-theme43.css" />

    <!-- Cargar estilos específicos del login -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
  mounted() {
    // Cargar los scripts específicos del login dinámicamente
    const jqueryScript = document.createElement('script');
    jqueryScript.src = '../../js/login/jquery.min.js';
    document.body.appendChild(jqueryScript);

    const loginScript = document.createElement('script');
    loginScript.src = '../../js/login/popper.min.js';
    document.body.appendChild(loginScript);

    const bundle = document.createElement('script');
    bundle.src = '../../js/login/bootstrap.bundle.min.js';
    document.body.appendChild(bundle);

    const mainScript = document.createElement('script');
    mainScript.src = '../../js/login/main.js';
    //document.body.appendChild(mainScript);
  },
  beforeUnmount() {
    // Eliminar scripts para evitar conflictos
    document.querySelectorAll('script[src*="login"]').forEach((script) => {
      script.remove();
    });
  }
};
</script>

