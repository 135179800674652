<template>
  <main class="show-section overflow-hidden">
    <div class="container" v-if="welcome || welcome2">
        <div class="start-wrapper">
            <section class="start" v-if="welcome">

                <div class="start-img">
                    <img src="images/img-1.jpg" alt="start1">
                </div>

                <article class="start-text">
                    <h2>Hola, {{ profile.name }}!</h2>
                    <p>
                        ¡Bienvenido a tu Evaluación 360°! 
                        <br>
                        Este es un espacio diseñado para ayudarte a descubrir tus fortalezas y áreas de mejora. 
                    </p>
                </article>

                <button class="next delay-1000ms start-btn" @click="comenzarEvaluacion2">Comenzar la evaluación</button>
            </section>

            
            <section class="start" v-if="welcome2">

                <div class="start-img">
                    <img src="images/img-2.jpg" alt="start2">
                </div>

                <article class="start-text">
                    <h2>
                        Responde con calma y sinceridad. Busca un lugar sin distracciones para reflexionar y brindar respuestas que reflejen tu verdadera percepción.
                    </h2>
                </article>
                
                <button class="next delay-1000ms start-btn" @click="comenzarEvaluacion">Continuar</button>
            </section>
        </div>
    </div>
    

    <!-- forms -->
    <form method="post" v-if="mostrarPasos">

        <!-- header -->
        <header>
            <h3>
              Comparte tu perspectiva sobre el equipo. Tu opinión es valiosa para fortalecer la colaboración y mejorar el desempeño conjunto.
            </h3>
        </header>


        <div class="form-inner">
            <div class="container">
              
              <div v-if="preguntas[preguntaActual]?.seccion === 1">

                <div v-if="preguntas[preguntaActual]?.tipo_pregunta === 'opcion'">
                  <section class="steps form-wrapper" >
                    <fieldset>
                      <div class="container">

                          <label class="label-head">{{ preguntaActual + 1 }}. {{ preguntas[preguntaActual]?.texto }}</label>
                          <div class="field-wrap">
                            <div v-for="opcion in preguntas[preguntaActual]?.opciones" :key="opcion.id">
                              <div class="radio-field revealfield delay-100ms">


                                  <div v-if="opcion.id == 1">
                                    <input type="radio" :id="'opcion_' + opcion.id" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">


                                    <label class="op1" :for="'opcion_' + opcion.id">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 2">
                                    <input type="radio" :id="opcion.id" :name="'pregunta_' + preguntaActual" 
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op2" :for="opcion.id">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 3">
                                    <input type="radio" :id="opcion.id" :name="'pregunta_' + preguntaActual" 
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op3" :for="opcion.id">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 4">
                                    <input type="radio" :id="opcion.id" :name="'pregunta_' + preguntaActual" 
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op4" :for="opcion.id">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 5">
                                    <input type="radio" :id="opcion.id" :name="'pregunta_' + preguntaActual" 
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op5" :for="opcion.id">{{ opcion.texto }}</label>
                                  </div>

                              </div>
                            </div>
                        </div>
                      </div>
                    </fieldset>

                    <div class="next-prev">
                          <button class="next" type="button" id="step1btn" @click.prevent="enviarRespuesta"> 
                            <!-- @click="guardarSeleccion"> -->
                              Siguiente
                              <i class="fa-solid fa-arrow-right"></i>
                          </button>
                      </div>
                  </section>
                </div>


                <!-- Selecciona el jefe directo -->
                <div v-if="preguntas[preguntaActual]?.tipo_pregunta === 'unica'">
                  <section class="steps form-wrapper">
                    <fieldset>
                      <div class="container">

                          <label class="label-head">{{ preguntaActual + 1 }}. {{ preguntas[preguntaActual]?.texto }}</label>

                          <table class="table">
                              <thead>
                                <tr>
                                  <th v-for="(col, index) in 5" :key="index">Jefe directo</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="fila in filas" :key="fila">
                                  <td v-for="persona in personas.slice((fila - 1) * 5, fila * 5)" :key="persona.id_empleado">

                                    <div class="radio-field revealfield delay-100ms">


                                        <input type="radio" :value="persona.id_empleado" v-model="seleccionJefe"
                                          @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, persona.id_empleado, 'unica')" />
                                        <label class="op" :for="persona.id_empleado">
                                          {{ persona.nombre }}
                                        </label>
                                      
                                    </div>


                                  </td>
                                </tr>
                              </tbody>
                            </table>

                      </div>
                    </fieldset>

                    <div class="next-prev">
                        <button class="next" type="button" id="step1btn" @click="enviarRespuesta">
                            Siguiente
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                  </section>
                </div>


                <!-- Selecciona los compañeros con el mismo puesto --><!-- Selecciona los subordinados  -->
                <div v-if="preguntas[preguntaActual]?.tipo_pregunta === 'multiple'">
                  <section class="steps form-wrapper">
                    <fieldset>
                      <div class="container">

                          <label class="label-head">{{ preguntaActual + 1 }}. {{ preguntas[preguntaActual]?.texto }}</label>
                          <div v-if="preguntas[preguntaActual]?.id_pregunta === 3">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th v-for="(col, index) in 5" :key="index">Compañeros</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="fila in filas" :key="fila">
                                  <td v-for="persona in personas.slice((fila - 1) * 5, fila * 5)" :key="persona.id_empleado">
                                    
                                    <div class="radio-field revealfield delay-100ms">
                                      <input 
                                        type="checkbox" 
                                        :value="persona.id_empleado" 
                                        
                                        :id="'par-' + persona.id_empleado" 
                                        @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, persona.id_empleado, 'multiple')"/>

                                      <label :for="'par-' + persona.id_empleado">
                                        {{ persona.nombre }}
                                      </label>
                                    </div>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div v-if="preguntas[preguntaActual]?.id_pregunta === 4">
                            <table class="table">
                            <thead>
                              <tr>
                                <th v-for="(col, index) in 5" :key="index">Subordinados</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="fila in filas" :key="fila">
                                <td v-for="persona in personas.slice((fila - 1) * 5, fila * 5)" :key="persona.id_empleado">
                                  
                                  <div class="radio-field revealfield delay-100ms">
                                    <input 
                                      type="checkbox" 
                                      :value="persona.id_empleado" 
                                      
                                      :id="'subordinado-' + persona.id_empleado" 
                                      @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, persona.id_empleado, 'multiple')"/>

                                    <label :for="'subordinado-' + persona.id_empleado">
                                      {{ persona.nombre }}
                                    </label>
                                  </div>

                                </td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                      </div>
                    </fieldset>

                    <div class="next-prev">
                        <button class="next" type="button" id="step1btn" @click="enviarRespuesta">
                            Siguiente
                            <i class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                  </section>
                </div>
              </div>


              <div v-if="[2, 3, 4].includes(preguntas[preguntaActual]?.seccion)">
                <div v-if="preguntas[preguntaActual]?.tipo_pregunta === 'opcion'">
                  <section class="steps form-wrapper" :key="preguntaActual">
                    <fieldset>
                      <div class="container">
                        <label class="label-head">
                          {{ preguntaActual + 1 }}. {{ preguntas[preguntaActual]?.texto }}
                        </label>

                        <!-- Tabla para alinear evaluados en columnas -->
                        <table class="evaluation-table">
                          <thead>
                            <tr>
                              <th v-for="(evaluado, index) in preguntas[preguntaActual]?.id_evaluado.split(',')" :key="'th-' + evaluado">
                                Evaluando a: {{ preguntas[preguntaActual]?.nombre_evaluado.split(',')[index] }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- Iterar sobre las opciones de respuesta -->
                            <tr v-for="opcion in preguntas[preguntaActual]?.opciones" :key="'tr-' + opcion.id">
                              <td v-for="evaluado in preguntas[preguntaActual]?.id_evaluado.split(',')" :key="'td-' + evaluado">
                                
                                <div class="radio-field revealfield delay-100ms">
                                <input type="radio" 
                                :id="'opcion_' + opcion.id + '_evaluado_' + evaluado + '_pregunta_' + preguntaActual"

                                :name="'pregunta_' + preguntaActual + '_evaluado_' + evaluado"
                                :value="opcion.valor"
                                @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion', evaluado)"/>

                                <label :for="'opcion_' + opcion.id + '_evaluado_' + evaluado+ '_pregunta_' + preguntaActual">
                                  {{ opcion.texto }}
                                </label>

                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </fieldset>

                    <div class="next-prev">
                      <button class="next" type="button" id="step1btn" @click.prevent="enviarRespuesta">
                        Siguiente
                        <i class="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </section>
                </div>
              </div>

              <div v-if="preguntas[preguntaActual]?.seccion === 5">

                  <section class="start" v-if="showIntro5">

                    <div class="start-img">
                        <img src="images/img-4.jpg" alt="start2">
                    </div>

                    <article class="start-text">
                        <h2>
                            Estas a punto de inciar la sección de autoevaluación
                        </h2>
                    </article>
                    
                    <button class="next delay-1000ms start-btn" @click="avanzaIntro(5)">Iniciar autoevaluación</button>
                </section>

                <div v-if="preguntas[preguntaActual]?.tipo_pregunta === 'opcion' && showIntro5 == false">
                  <section class="steps form-wrapper" :key="preguntaActual">
                    <fieldset>
                      <div class="container">

                          <label class="label-head">{{ preguntaActual + 1 }}. {{ preguntas[preguntaActual]?.texto }}</label>
                          <div class="field-wrap">
                            <div v-for="opcion in preguntas[preguntaActual]?.opciones" :key="opcion.id">
                              <div class="radio-field revealfield delay-100ms">

                                  <div v-if="opcion.id == 1">

                                  
                                    <input type="radio" :id="'opcion_' + opcion.id + '_pregunta_' + preguntaActual" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op1" :for="'opcion_' + opcion.id+ '_pregunta_' + preguntaActual">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 2">
                                    <input type="radio" :id="'opcion_' + opcion.id + '_pregunta_' + preguntaActual" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op2" :for="'opcion_' + opcion.id+ '_pregunta_' + preguntaActual">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 3">
                                    <input type="radio" :id="'opcion_' + opcion.id + '_pregunta_' + preguntaActual" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op3" :for="'opcion_' + opcion.id+ '_pregunta_' + preguntaActual">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 4">
                                    <input type="radio" :id="'opcion_' + opcion.id + '_pregunta_' + preguntaActual" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op4" :for="'opcion_' + opcion.id+ '_pregunta_' + preguntaActual">{{ opcion.texto }}</label>
                                  </div>

                                  <div v-if="opcion.id == 5">
                                    <input type="radio" :id="'opcion_' + opcion.id + '_pregunta_' + preguntaActual" :name="'pregunta_' + preguntaActual"
                                    :value="opcion.valor" @change="guardarRespuesta(preguntas[preguntaActual]?.id_pregunta, opcion.valor, 'opcion')">
                                    <label class="op5" :for="'opcion_' + opcion.id+ '_pregunta_' + preguntaActual">{{ opcion.texto }}</label>
                                  </div>

                                  
                              </div>
                            </div>
                        </div>
                      </div>
                    </fieldset>

                    <div class="next-prev">
                          <button class="next" type="button" id="step1btn" @click.prevent="enviarRespuesta"> 
                            <!-- @click="guardarSeleccion"> -->
                              Siguiente
                              <i class="fa-solid fa-arrow-right"></i>
                          </button>
                      </div>
                  </section>
                </div>

              </div>

            </div>
        </div>

        <!-- footer -->
        <footer>
            <div class="step-bar">
                <div class="bar-inner">
                    <div class="fill"></div>
                </div>
                <div class="complete-rate">
                    <span> {{porcentajeAvance.toFixed(0)}}</span>% Completado
                </div>
            </div>
        </footer>
    </form>

  </main>

  <div id="error">
  </div>

    <div v-if="mostrarModal" class="modal">
      <div class="modal-content">
        <h2>¡Gracias {{ profile.name }}!</h2>
        <p>Has completado la evaluación.</p>
        <button class="next delay-1000ms start-btn" @click="cerrarModal">Aceptar</button>
      </div>
    </div>
</template>

<script>
import { useProfileStore } from '@/stores/apps/profile/editprofile';
import { computed, ref, onMounted, nextTick  } from 'vue';
import axios from 'axios';
import router from '@/router';

export default {
  name: 'EvaluacionMain',

  setup() {
    const welcome = ref(true);
    const welcome2 = ref(false);
    const mostrarPasos = ref(false);
    const preguntas = ref([]);
    const personas = ref([]); // Lista de personas desde la base de datos
    const preguntaActual = ref(0); // Controlar la pregunta actual
    const seleccionJefe = ref(''); // Guardar selección del jefe
    const mostrarModal = ref(false);
    const respuestas = ref([]); // Guarda todas las respuestas enviadas
    const porcentajeAvance = ref(0);
const showIntro5 = ref(true); // Iniciar con la pantalla de introducción activa

    // const seleccionJefes = ref([]); // Para las preguntas de opción múltiple

    const profileStore = useProfileStore();

    // Datos del perfil del usuario
    const profile = computed(() => ({
      proyecto: profileStore.proyecto,
      clave: profileStore.clave,
      name: profileStore.name,
      image: profileStore.image
    }));

    

    // Calcular cuántas filas necesita la tabla
    const filas = computed(() => {
      return Math.ceil(personas.value.length / 5);
    });

    // Obtener preguntas desde la base de datos
    const obtenerPreguntas = async () => {
      try {
        const mostrarModal = ref(false);

        const token = localStorage.getItem('token');
        const response = await axios.post(
          //'http://localhost:3000/api/preguntas/listado',
          'https://app360.tuevaluacion.com/api/preguntas/listado', // Ajustar según tu ruta en el backend
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        preguntas.value = response.data;

        if (preguntas.value.length === 0) {
          mostrarModal.value = true; // Mostrar el modal
        }

      } catch (error) {
        if (error.response && error.response.status === 404) {
          mostrarModal.value = true;
          await nextTick(); // Asegurar que Vue detecte el cambio
        }
      }
    };

    // Obtener listado de personas desde la base de datos
    const obtenerPersonas = async () => {
      try {
        //console.log(profileStore.clave,profileStore.proyecto, profileStore.name, profileStore.clave_proyecto );
        
        const token = localStorage.getItem('token');
        const response = await axios.post(
          //'http://localhost:3000/api/personas/listado', // Ajustar según tu ruta en el backend
          'https://app360.tuevaluacion.com/api/personas/listado', // Ajustar según tu ruta en el backend
          {
            clave_proyecto: profileStore.proyecto, // Aquí puedes usar email si corresponde
            clave_empleado: profileStore.clave
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        personas.value = response.data;
      } catch (error) {
        console.error('Error al obtener el listado de personas');
      }
    };


    const guardarRespuesta = (preguntaId, respuestaValor, tipoRespuesta, idEvaluado) => {
    //console.log(preguntaId, respuestaValor, tipoRespuesta, idEvaluado);

    // Buscar si ya existe una respuesta guardada para esa pregunta y ese evaluado
    let respuestaExistente = respuestas.value.find(
        (respuesta) => respuesta.idPregunta === preguntaId && respuesta.numEvaluado === idEvaluado
    );

    if (tipoRespuesta === 'multiple') {
        if (respuestaExistente) {
            // Convertir la respuesta en un array si aún no lo es
            if (!Array.isArray(respuestaExistente.respuesta)) {
                respuestaExistente.respuesta = [respuestaExistente.respuesta];
            }

            // Verificar si ya hay 5 opciones seleccionadas y se intenta agregar otra
            if (respuestaExistente.respuesta.length >= 5 && !respuestaExistente.respuesta.includes(respuestaValor)) {
                alert('Solo puedes seleccionar hasta 5');
                 // ** Forzar deselección visual**
                setTimeout(() => {
                    const checkbox = document.querySelector(`input[value="${respuestaValor}"]`);
                    if (checkbox) checkbox.checked = false;
                }, 100);

                return; // Salir sin agregar la opción extra
            }

            // Verificar si la opción ya está seleccionada
            const index = respuestaExistente.respuesta.indexOf(respuestaValor);
            if (index === -1) {
                // Agregar la opción si no está en la lista
                respuestaExistente.respuesta.push(respuestaValor);
            } else {
                // Eliminar la opción si el usuario la deselecciona
                respuestaExistente.respuesta.splice(index, 1);
            }
        } else {
            // Agregar nueva respuesta como array
            respuestas.value.push({
                idPregunta: preguntaId,
                respuesta: [respuestaValor], // Se almacena como array
                tipo: tipoRespuesta,
                numEvaluado: idEvaluado
            });
        }
    } else {
        // Para respuestas de opción única, actualizar o agregar normalmente
        if (respuestaExistente) {
            respuestaExistente.respuesta = respuestaValor;
            respuestaExistente.tipo = tipoRespuesta;
        } else {
            respuestas.value.push({
                idPregunta: preguntaId,
                respuesta: respuestaValor,
                tipo: tipoRespuesta,
                numEvaluado: idEvaluado
            });
        }
    }
};



    const avanzaIntro = (intro) => {
      // Si la pregunta pertenece a la Sección 5, mostrar la introducción
        if (intro === 5) {
            showIntro5.value = false; 
        }
    };

    // Avanzar a la siguiente pregunta
    const avanzarPregunta = () => {
      
      // se obtiene el resto de las preguntas, con base a lo que respondio en las primeras preguntas
      
      respuestas.value = [];

      if (preguntaActual.value < preguntas.value.length - 1) {

        preguntaActual.value++;
        
        

        // Se calcula el porcentaje en base a la pregunta que avanza con respecto del total
        porcentajeAvance.value = (preguntaActual.value*100)/preguntas.value.length;
      }else{
        obtenerPreguntas();
        preguntaActual.value = 0;
        
      }
    
      
    };

    function comenzarEvaluacion() {
      welcome.value = false;
      welcome2.value = false;
      mostrarPasos.value = true;
    }

    function comenzarEvaluacion2() {
      welcome.value = false;
      welcome2.value = true;
    }
    

    const enviarRespuesta = async () => {
      try {

        const token = localStorage.getItem('token');
        for (let respuesta of respuestas.value) {
          await axios.post(
            //'http://localhost:3000/api/respuestas/guardar',
            'https://app360.tuevaluacion.com/api/respuestas/guardar',
            {
              id_pregunta: respuesta.idPregunta,
              // : respuesta.respuesta,
              respuesta: Array.isArray(respuesta.respuesta) ? respuesta.respuesta.join(',') : respuesta.respuesta,
              tipo_respuesta: respuesta.tipo,
              numEvaluado : respuesta.numEvaluado,
              tipoEvaluado : respuesta.tipoEvaluado
              //tipo_evaluado : respuesta.numEvaluado
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
        }

        avanzarPregunta(); // Avanzar después de guardar la respuesta
      } catch (error) {
        console.error('Error al enviar las respuestas:', error);
      }
    };

    const cerrarModal = () => {
      localStorage.removeItem('token'); // Opcional: Eliminar token
      router.replace('/'); // Redirigir al login
    };

    // Cargar datos al montar el componente
    onMounted(() => {
      obtenerPreguntas();
      // console.log(preguntas.value.length);
      obtenerPersonas();
    });

    

    return {
      profile,
      preguntas,
      personas,
      preguntaActual,
      seleccionJefe,
      //seleccionJefes,
      // guardarSeleccion,
      mostrarPasos,
      comenzarEvaluacion,
      welcome,
      welcome2,
      comenzarEvaluacion2,
      filas,
      enviarRespuesta,
      guardarRespuesta,
      cerrarModal,
      mostrarModal,
      porcentajeAvance,
      avanzaIntro,
      showIntro5
    };
  }
};
</script>



<style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th{
    border: 1px solid #ddd;
    padding: 10px;
  }

  label {
    display: flex;
  }


  .evaluation-table {
    width: 100%;
    border-collapse: collapse;
  }

  .evaluation-table th {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  .evaluation-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    width: 50%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
</style>

