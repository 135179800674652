<template>
  <div class="form-body form-left" >
    <div class="iofrm-layout">
      <div class="img-holder">
        <div class="bg"></div>
      </div>
      <div class="form-holder">
        <div class="form-content">
          <div class="form-items">
            <div class="website-logo-inside logo-normal">
              <a href="./">
                <div class="logo2">
                  <img class="logo-size" src="images/logotipo_evaluacion_360.jpg" alt="Evaluación 360" />
                </div>
              </a>
            </div>
            <h3 class="font-md">Evaluación 360</h3>
            <p>
              Para contestar la evaluación 360 debes ingresar la clave proporcionada por la empresa.
            </p>
            <form @submit.prevent="login">
              <input
                v-model="claveProyecto"
                class="form-control"
                type="text"
                name="clave_proyecto"
                placeholder="Clave del proyecto"
                required
              />
              <input
                v-model="claveAcceso"
                class="form-control"
                type="password"
                name="clave_acceso"
                placeholder="Clave de acceso"
                required
              />
              <div class="form-button d-flex">
                <button id="submit" type="submit" class="btn btn-primary">Entrar</button>
              </div>
              <p v-if="error" class="error">{{ error }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import axios from 'axios';
  import { useProfileStore } from '@/stores/apps/profile/editprofile'; // Según tu estructura
  //import { useProfileStore } from '../../stores/apps/profile/editprofile.js';
  
  // Definición de variables reactivas
  const claveProyecto = ref('');
  const claveAcceso = ref('');
  const error = ref('');

  const router = useRouter(); // Obtener acceso al router

  // Definición de la función login
  const login = async () => {
    try {
      //const response = await axios.post('http://localhost:3000/login', {
      const response = await axios.post('https://app360.tuevaluacion.com/login', {
        clave_proyecto: claveProyecto.value, // Aquí puedes usar email si corresponde
        clave_empleado: claveAcceso.value
      });
      const token = response.data.token;

      // Guardar el token en localStorage
      localStorage.setItem('token', token);

      // Guardar la información del usuario en Vuex o Pinia
      const profileStore = useProfileStore();
      profileStore.setProfile(response.data.user);

      
      // Redirigir al Dashboard
      router.push('/dashboard');

    } catch (err) {
      error.value = err.response?.data?.message || 'Error al iniciar sesión';
    }
  };
</script>

<script>
  export default {
    name: 'UserLogin', // Cambiar el nombre del componente
    mounted() {
      //console.log('Componente Login.vue cargado'); // Mensaje de depuración
    },
  };
</script>

<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
</style>
