// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginLayout from '@/layouts/LoginLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Login from '@/views/Login.vue';
import EvaluacionMain from '@/views/EvaluacionMain.vue';

const routes = [
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      }
    ]
  },
  {
    path: '/dashboard',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'EvaluacionMain',
        component: EvaluacionMain,
         meta: { requiresAuth: true } // Marcar como ruta protegida
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


// Guard de autenticación para proteger rutas
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.meta.requiresAuth && !token) {
    //console.warn('🔒 Token no encontrado o expirado. Redirigiendo al login...');
    next('/'); // Redirigir al login
  } else {
    next(); // Continuar con la navegación
  }
});

export default router;
