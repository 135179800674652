<template>
  <div>
    <!-- font-awesome -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css">
    
    <!-- Bootstrap-5 -->
    <link rel="stylesheet" href="../css/app/bootstrap.min.css">
    <!-- custom-styles -->
    <link rel="stylesheet" href="../css/app/style.css">
    <link rel="stylesheet" href="../css/app/responsive.css">
    <link rel="stylesheet" href="../css/app/animation.css">
    <!-- color sceme 
    <link rel="stylesheet" href="../css/app/colorvariants/default.css" id="defaultscheme">

    <link rel="stylesheet" href="../css/app/colorvariants/colorscheme-8.css" id="colorscheme-8">
    -->
    <link rel="stylesheet" href="../css/app/colorvariants/colorscheme-5.css" id="colorscheme-5">
    <!-- color switcher -->
    <link rel="stylesheet" href="../css/app/colorswitcher/assets/css/colorswitcher.css">

    
    
    <router-view  />


  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  mounted() {

    const bundle = document.createElement('script');
    bundle.src = '../../js/app/custom.js';
    //document.body.appendChild(bundle);

    
  }
};
</script>
  

  <!--
    <script src="assets/js/bootstrap.min.js"></script>
    <script src="assets/js/jquery-3.6.1.min.js"></script>
    <script src="assets/js/custom.js"></script>
    <script src="assets/js/callswitcher.js"></script>

    -->