// src/stores/apps/profile/editprofile.js
import { defineStore } from 'pinia';

export const useProfileStore = defineStore('profile', {
  state: () => ({
    proyecto: '',
    clave: '',
    name: '',
    image: '',
  }),
  persist: true, // Si tienes el plugin de persistencia configurado
  actions: {
    setProfile(user) {
      this.proyecto = user.proyecto;
      this.clave = user.clave;
      this.name = user.name;
      this.image = user.image;
    },
    clearProfile() {
      this.proyecto = '';
      this.clave = '';
      this.name = '';
      this.image = '';
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.clave
  }
});
