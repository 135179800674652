// src/main.js
import { createApp, nextTick } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persistedstate';
import router from './router'; // Vue Router
import axios from 'axios';

const app = createApp(App);

// Configurar Pinia
const pinia = createPinia();
pinia.use(piniaPersist);
app.use(pinia);
app.use(router);

// Variable para evitar múltiples redirecciones simultáneas
let isRedirecting = false;

// Configurar interceptor de Axios para manejar token expirado
axios.interceptors.response.use(
  response => response, // Devolver la respuesta si es exitosa
  async error => {
    //if (error.response && error.response.status === 401 && !isRedirecting) {
    if (error.response && (error.response.status === 401 || error.response.status === 403) && !isRedirecting) {

      //console.error('Token inválido o expirado, redirigiendo al login...');

      // Marcar que estamos en proceso de redirección
      isRedirecting = true;

      // Eliminar el token del almacenamiento local
      localStorage.removeItem('token');

      // Esperar a que Vue procese los cambios y redirigir al login
      await nextTick();
      router.push('/').finally(() => {
        isRedirecting = false; // Resetear bandera después de redirigir
      });
    }

    return Promise.reject(error);
  }
);

app.mount('#app');
