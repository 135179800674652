<template>
  
    <!-- Aquí se renderizarán las vistas según la ruta -->
    <router-view />
  
</template>

<script>
export default {
  name: 'App',
  mounted() {
    console.log('App.vue montado correctamente');
    // Verifica si ya estás en la ruta correcta para evitar redirección en bucle
    if (this.$route.path === '/') {
      console.log('Ya estamos en la ruta raíz, no redirigir.');
    }
  }
};
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/
</style>
